/* styles.css */
.container {
    padding: 24px;
    max-width: 400px;
    margin: auto;
  }
  
  .header {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 16px;
  }
  
  .select-box {
    width: 100%;
    padding: 8px;
    font-size: 1rem;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  .button {
    margin-top: 16px;
    padding: 10px 16px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .button:hover {
    background-color: #0056b3;
  }
  
  .card {
    size: 16px;
    margin-top: 16px;
    padding: 16px;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    font-weight: 700;

  }
  
  .card p {
    margin: 4px 0;
    font-size: 1rem;
  }
  